import gql from 'graphql-tag';

export const LANGUAGES = gql`
  query languages {
    languages {
      __typename
      ... on Language {
        id
        language_code
        native_name
        translations_version
        name
        flag_url
        default
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;
