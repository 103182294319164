export const CREATE_ROLE = `
mutation createRole(
    $tenant_id: Int!
    $input: CreateRoleInput!,
  ){
    createRole(
      tenant_id: $tenant_id
      input: $input
    ){
      __typename
      ... on Role{
        id
        name
      }
      ... on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const UPDATE_ROLE = `
mutation updateRole(
    $tenant_id: Int!
    $input:  UpdateRoleInput!,
  ){
    updateRole(
      tenant_id: $tenant_id
      input: $input
    ){
      __typename
      ... on Role{
        id
        name
      }
      ... on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const DELETE_ROLE = `
mutation deleteRole(
    $tenant_id: Int!
    $id: Int!
  ){
    deleteRole(
      tenant_id: $tenant_id
      id: $id
    ){
          __typename
      ... on Role{
        id
        name
      }
      ... on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
`;
