export const UPSERT_ROLE_PERMISSION = `
  mutation upsertRolePermission($tenant_id: Int!, $input:  UpsertRolePermissionInput!) {
    upsertRolePermission(tenant_id: $tenant_id, input: $input) {
      __typename
      ... on RolePermission {
        role_id
        permission_id
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;
