export const UPDATE_TENANT_SETTING = `
mutation updateTenantSettingValue(
  $tenant_id: Int!
  $setting_id: Int!
  $value: String!
) {
  updateTenantSettingValue(
    tenant_id: $tenant_id
    setting_id: $setting_id
    value: $value
  ) {
    __typename
    ... on TenantSettingValue {
      value
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_TENANT_SETTING = `
mutation createTenantSettingValue(
  $tenant_id: Int!
  $setting_id: Int!
  $value: String!
) {
  createTenantSettingValue(
    tenant_id: $tenant_id
    setting_id: $setting_id
    value: $value
  ) {
    __typename
    ... on TenantSettingValue {
      value
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
