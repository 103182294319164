export const UPDATE_SETTING = `
  mutation updateAppSetting($input: UpdateAppSettingInput!) {
    updateAppSetting(input: $input) {
      __typename
      ... on AppSetting {
        setting_value
        setting_name
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const UPDATE_SETTING_TRANSLATION = `
mutation updateAppSettingTranslation($input: UpdateAppSettingTranslationInput!) {
  updateAppSettingTranslation(input: $input) {
    __typename
    ... on AppSettingTranslation {
      id
      description
      app_setting_id
      language_id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
