import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

export const defaultLanguage = import.meta.env.VITE_FALLBACK_LNG || 'es';
const debugMode = import.meta.env.VITE_I18N_DEBUG === 'TRUE';

const languageDetector = new LanguageDetector();
const detectLanguageFunction = languageDetector.detect;
languageDetector.detect = (detectionOrder) => {
  const detectedLanguage = detectLanguageFunction.call(
    languageDetector, // override internal this
    detectionOrder,
  );
  if (Array.isArray(detectedLanguage)) {
    return detectedLanguage.map((detection) => detection.split('-')[0]);
  } else {
    return detectedLanguage;
  }
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    fallbackLng: defaultLanguage,
    load: 'languageOnly',
    returnNull: true,
    debug: debugMode,
    backend: {
      loadPath: '/locales/{{lng}}/translations.json',
    },
  });

i18n.on('languageChanged', () => {});

export default i18n;
