export const WALLET_REASONS = `
query walletReasons(
  $tenant_id: Int!
  $skip: Int
  $take: Int
  $orderBy: WalletReasonOrderInput
){
  walletReasons(
    tenant_id:$tenant_id,
    skip:$skip,
    take:$take,
    orderBy:$orderBy
  ){
    __typename
    ...on WalletReason{
      id
      translation_key
      type
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }    
  }
}
`;
