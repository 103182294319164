import { renderRoutes } from 'react-router-config';
import routes from './routes/routes';
import SendActivity from './sendActivity';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { IRouteConfig } from './interfaces/routeConfig';
import { ContextApp } from './contexts/ContextApp';

interface AppContentProps {
  onRouteChange?: (route: string) => void;
}

const AppContent = ({ onRouteChange }: AppContentProps) => {
  const location = useLocation();
  const { t } = useContext(ContextApp);

  useEffect(() => {
    onRouteChange?.(location.pathname);
  }, [location]);

  const recursiveRouteTranslate = (routes: IRouteConfig[]) => {
    routes.forEach((route) => {
      if (route.translation_key) route.name = t(route.translation_key as never);

      if (route.routes) {
        route.routes = [...route.routes];
        recursiveRouteTranslate(route.routes);
      }
    });
  };
  recursiveRouteTranslate(routes);

  SendActivity();
  return renderRoutes(routes);
};

export default AppContent;
