export const CREATE_WALLET_MOVEMENT = `
mutation createWalletMovement(
  $input:CreateWalletMovementInput!,
  $tenant_id:Int!
){
  createWalletMovement(
    input:$input,
    tenant_id:$tenant_id
  ){
    __typename
    ...on WalletMovement{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key      
    }
  }
}
`;
