export const SETTINGS = `
  query getAppSettings(
    $tenant_id: Int!
    $filter:  FilterAppSettingInput,
    $searchText : String,
    $orderBy:  AppSettingOrderInput,
    $skip: Int,
    $take: Int) {
    getAppSettings(
      tenant_id: $tenant_id
      filter: $filter
      searchText :$searchText
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      __typename
      ... on AppSetting {
        id
        screen_name
        setting_name
        setting_value
        description
        is_readonly
        app_setting_translation{
          id
          description
          language_id
        }
        is_public
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const SETTING_KEY = `
query getAppSettingByKey(
  $input: SettingkeyInput!,
  ){
    getAppSettingByKey(
      input: $input
    ){
      __typename
      ... on AppSetting{
        setting_name
        setting_value
        description
        app_setting_translation{
          id
          description
          language_id
        }
      }
      ... on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
  `;

export const SETTING_KEY_FOR_ADMIN = `
query getAppSettingByKeyForAdmin(
  $input: SettingkeyInput!,
  ){
    getAppSettingByKeyForAdmin(
      input: $input
    ){
      __typename
      ... on AppSetting{
        setting_name
        setting_value
        description
        app_setting_translation{
          id
          description
          language_id
        }
      }
      ... on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
  `;
