import { FetchResult } from '@apollo/client';
import { IOperations } from '../../../interfaces/operations';
import {
  CacheMutationsRecord,
  CacheRollbackMutationsRecord,
  MutationHandler,
  MutationRollback,
} from './types';
import { parse, stringify } from 'flatted';

type Mutations = 'createOperation' | 'deleteOperation' | 'updateOperation';

// En este archivo se determina como se modifica la cache ante las diferentes mutaciones, y sus respectivos rollback en caso de error
// Por cada mutación podemos contar con múltiples campos de la cache que deben ser modificados

const handleCreateOperation: MutationHandler<{
  createOperation: IOperations;
}> = {
  operations: (previousQueryResult, { mutationResult }) => {
    const _operations: IOperations[] = previousQueryResult?.operations || [];
    const operations = [..._operations];
    const newOperation = mutationResult.data?.createOperation!;

    const index = operations.findIndex(
      (operation) => operation.id === newOperation.id,
    );

    // Replace at existing index or push to the end
    index === -1
      ? operations.push(newOperation)
      : operations.splice(index, 1, newOperation);
    return { operations };
  },
};

const handleRollbackCreateOperation: MutationRollback<IOperations> = {
  operations: (
    queryCurrentValue,
    mutationCurrentValue,
    _mutationPreviousValue,
  ) => {
    const operations: IOperations[] = queryCurrentValue?.operations || [];
    const id = mutationCurrentValue?.id;
    return {
      operations: operations.filter((operation) => operation.id !== id),
    };
  },
};

const handleUpdateOperation: MutationHandler<{
  updateOperation: IOperations;
}> = {
  operations: (previousQueryResult, { mutationResult }) => {
    const _operations: IOperations[] = previousQueryResult?.operations || [];
    const operations: typeof _operations = parse(stringify(_operations));
    const updatedOperation = mutationResult.data?.updateOperation!;
    const index = operations.findIndex(
      (operation) => operation.id === updatedOperation.id,
    );

    // Replace at existing index or push to the end
    index === -1
      ? operations.push(updatedOperation)
      : operations.splice(index, 1, updatedOperation);

    return { operations };
  },
};

const handleRollbackUpdateOperation: MutationRollback<IOperations> = {
  operations: (
    queryCurrentValue,
    mutationCurrentValue,
    mutationPreviousValue,
  ) => {
    const _operations: IOperations[] = queryCurrentValue?.operations || [];
    const operations = [..._operations];
    const index = operations.findIndex(
      (operation) => operation.id === mutationCurrentValue?.id,
    );

    if (mutationPreviousValue) {
      // Replace at existing index or push to the end
      index === -1
        ? operations.push(mutationPreviousValue)
        : operations.splice(index, 1, mutationPreviousValue);
    }

    return { operations };
  },
};

const handleDeleteOperation: MutationHandler<{
  deleteOperation: IOperations;
}> = {
  operations: (previousQueryResult, { mutationResult }) => {
    const operations: IOperations[] = previousQueryResult?.operations || [];
    const id = mutationResult.data?.deleteOperation?.id;
    return {
      operations: operations.filter((operation) => operation.id !== id),
    };
  },
};

const handleRollbackDeleteOperation: MutationRollback<IOperations> = {
  operations: (
    queryCurrentValue,
    _mutationCurrentValue,
    mutationPreviousValue,
  ) => {
    const operations: IOperations[] = queryCurrentValue?.operations || [];
    const newOperation = mutationPreviousValue;
    return { operations: [...operations, newOperation] };
  },
};

const optimisticValuesCreateOperation = (
  optimisticData: { createOperation: IOperations },
  data: FetchResult<{ createOperation: IOperations }>,
) => {
  if (!optimisticData || !data) return [];

  const optimisticId = optimisticData.createOperation.id;

  const realId = data.data?.createOperation.id;

  if (!optimisticId || !realId) return [];

  return [[optimisticId, realId]] as ([string, string] | [number, number])[];
};

export const operationCacheHandlers: CacheMutationsRecord<
  Mutations,
  IOperations
> = {
  createOperation: handleCreateOperation,
  updateOperation: handleUpdateOperation,
  deleteOperation: handleDeleteOperation,
};

export const operationCacheRollbackHandlers: CacheRollbackMutationsRecord<
  Mutations,
  IOperations
> = {
  createOperation: handleRollbackCreateOperation,
  updateOperation: handleRollbackUpdateOperation,
  deleteOperation: handleRollbackDeleteOperation,
};

export const optimisticValuesToReplaceOperation: {
  [Mutation in Mutations]: (
    optimisticData: { [key in Mutation]: IOperations },
    data: FetchResult<{ [key in Mutation]: IOperations }>,
  ) => ([string, string] | [number, number])[];
} = {
  createOperation: optimisticValuesCreateOperation,
  deleteOperation: () => [],
  updateOperation: () => [],
};
