export const UPDATE_PERMISSION = `
  mutation updatePermission($tenant_id: Int!, $input:  UpdatePermissionInput!) {
    updatePermission(tenant_id:$tenant_id, input: $input) {
      __typename
      ... on Permissions {
        id
        name
        code
        description
        uts
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;
