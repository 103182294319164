export const USER_PLANS = `
query userPlans(
  $filter:  FilterUserPlanInput,
  $orderBy:  UserPlanOrderInput,
  $skip: Int,
  $take: Int,
  $tenant_id: Int!,
) {
  userPlans(
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
    tenant_id: $tenant_id
  ) {
    __typename
    ... on UserPlan{
      id
      plan_id
      user_id
      plan {
        id
        active
      }
    }
    ... on ResultError{
        status_code
        message
    }
  }
}`;
