// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { LoginProviders } from '../../enums/EnumsValues';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
  measurementId: import.meta.env.VITE_MEASUREMENT_ID,
};

// Initialize Firebase
const initializeFirebaseApp = () => {
  return firebase.initializeApp(firebaseConfig);
};

const firebaseApp = firebase;

const getAuthFirebase = (firebaseApp: any) => {
  return firebaseApp.auth();
};

const signInWithGoogle = async () => {
  const firebaseApp = initializeFirebaseApp();
  const auth = getAuthFirebase(firebaseApp);
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });
  try {
    const res = await signInWithPopup(auth, provider);
    const user = res.user;
    return user;
  } catch (err: any) {
    console.error(err);
  }
};

const signInWithMicrosoft = async () => {
  const firebaseApp = initializeFirebaseApp();
  const auth = getAuthFirebase(firebaseApp);
  const provider = new OAuthProvider(LoginProviders.MICROSOFT);
  provider.setCustomParameters({ prompt: 'select_account' });
  try {
    const res = await signInWithPopup(auth, provider);
    const user = res.user;
    return user;
  } catch (err: any) {
    console.error(err);
  }
};

const logoutFirebase = () => {
  initializeFirebaseApp();
  firebaseApp
    .auth()
    .signOut()
    .catch(() => {});
};
export {
  initializeFirebaseApp,
  firebaseApp,
  getAuthFirebase,
  logoutFirebase,
  firebaseConfig,
  signInWithGoogle,
  signInWithMicrosoft,
};
