export const TERMS_AND_CONDITIONS = `
query termsAndConditions{
  termsAndConditions{
    __typename
    ...on TermsAndConditions{
      id      
      data
      format
      version
      data_plain_text
      status_id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const USER_HAS_ACCEPTED_TERMS_AND_CONDITIONS = `
query userHasAcceptedTermsAndConditions{
  userHasAcceptedTermsAndConditions{
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const TERMS_AND_CONDITIONS_LIST = `
query termsAndConditionsList(
  $skip:Int, 
  $take:Int, 
  $orderBy: TermsAndConditionsOrderInput 
  $filter: FilterTermsAndConditionsInput
){
  termsAndConditionsList(
    skip:$skip, 
    take:$take, 
    orderBy:$orderBy, 
    filter:$filter
  ){
    __typename
    ...on TermsAndConditions{
      id
      data
      version
      format
      data_plain_text
      status_id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
