import ReactDOM from 'react-dom/client';
import App from './App';
import { Suspense } from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import ApolloProviderWrapper from './hooks/apollo/ApolloProviderWrapper';
import * as Sentry from '@sentry/react';
import { version } from '../package.json';
import { registerSW } from 'virtual:pwa-register'

const updateSW = registerSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    r && setInterval(async () => {
      if (r.installing || !navigator)
        return

      if (('connection' in navigator) && !navigator.onLine)
        return

      const resp = await fetch(swUrl, {
        cache: 'no-store',
        headers: {
          'cache': 'no-store',
          'cache-control': 'no-cache',
        },
      })

      if (resp?.status === 200)
        await r.update()
    }, (import.meta.env.VITE_SERVICE_WORKER_UPDATE_INTERVAL || 10) * 60 * 1000)
  }
})
updateSW();

if (
  import.meta.env.VITE_SENTRY_ENVS &&
  import.meta.env.VITE_ENV &&
  import.meta.env.VITE_SENTRY_ENVS.split('|').includes(import.meta.env.VITE_ENV)
) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENV,
    release: version,
    // debug: true,

    /****************************** PERFORMANCE ******************************
     *
     *   Esto habilita la recolección automática de "traces" (recomendado), pero no es
     *   necesario si se desea hacerlo manualmente.
     *   Si se desea usar de forma manual, remover la linea debajo, y agregar
     *   "Sentry.addTracingExtensions()" antes del llamado a "Sentry.init()".
     *
     * integrations: [new Sentry.BrowserTracing()],
     *
     *   Este valor debería cambiarse en producción (por ejemplo 0.2 para enviar solo el 20%),
     *   o usando "tracesSampler" en las transacciones para un control mas detallado
     *
     * tracesSampleRate: 1.0,
     *
     */

    /**************************** SESSION REPLAY ****************************
     *
     *   Esto determina que el ratio de muestras sea 10%. Se puede configurar
     *   al 100% durante desarrollo, y bajar el ratio en producción
     *
     * replaysSessionSampleRate: 0.1,
     *
     *   Si la sesión aun no ha sido muestreada, usa el valor de debajo para
     *   muestrear sesiones en las que ocurran errores
     *
     * replaysOnErrorSampleRate: 1.0,
     *
     * integrations: [
     *   new Sentry.Replay({
     *     // Configuración adicional del SDK va aqui, ejemplo:
     *     maskAllText: true,
     *     blockAllMedia: true,
     *   }),
     * ],
     *
     */
  });
}

const rootCreated = document.getElementById('root');
if (rootCreated) {
  const root = ReactDOM.createRoot(rootCreated);
  root.render(
    <ApolloProviderWrapper>
      <Suspense fallback={<PageLoading />}>
        <App />
      </Suspense>
    </ApolloProviderWrapper>,
  );
}
