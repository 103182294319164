export const CREATE_LOGIN_METHOD = `mutation createLoginMethod(
  $tenant_id: Int!
  $input: CreateLoginMethodInput!,
){
  createLoginMethod(
    tenant_id: $tenant_id
    input: $input
  ){
    __typename
    ... on LoginMethod{
      id
      user {
        id
        username
        email
        profile {
          phone
        }
       validated
      }
      
    }
    ... on ResultError{
      status_code
      message
    }
  }
}`;

export const DELETE_LOGIN_METHOD = `mutation deleteLoginMethod(
  $tenant_id: Int!
  $id: Int!
){
  deleteLoginMethod(
    tenant_id: $tenant_id
    id: $id
  ){
        __typename
    ... on LoginMethod{
      id
      user_id
      login_provider_id
      uid
    }
    ... on ResultError{
      status_code
      message
    }
  }
}`;
