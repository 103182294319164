import { Button } from 'antd';
import { IInvitation } from '../../interfaces/Invitation';
import { useContext } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
interface IAcceptRejectInvitation {
  onAccept: () => void;
  onReject: () => void;
  invitationData: IInvitation;
}
const AcceptRejectInvitation = ({
  onReject,
  onAccept,
  invitationData,
}: IAcceptRejectInvitation) => {
  const { t } = useContext(ContextApp);
  return (
    <div className="accept-invitation">
      {invitationData.tenant && invitationData.tenant?.is_system_tenant ? (
        <>
          <h3>
            {t('abm.acceptInvitationMessageToAdmin', {
              replace: {
                role: invitationData.role?.name,
              },
            })}
          </h3>
        </>
      ) : (
        <>
          <h3>
            {t('abm.acceptInvitationMessage', {
              replace: {
                role: invitationData.role?.name,
                tenant: invitationData.tenant?.name,
              },
            })}
          </h3>
        </>
      )}
      <div className="accept-invitation__buttons">
        <Button onClick={onReject} type="default">
          {t('abm.reject')}
        </Button>
        <Button onClick={onAccept} type="primary">
          {t('abm.accept')}
        </Button>
      </div>
    </div>
  );
};
export default AcceptRejectInvitation;
