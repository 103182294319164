export const CREATE_TAG = `
mutation createTag(
    $tenant_id: Int!,
    $input: CreateTagInput!,
  ){
    createTag(
      tenant_id: $tenant_id
      input: $input
    ){
      __typename
      ... on Tag{
        id
        name
        hex_color
      }
      ... on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
`;
