import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown } from 'antd';
import {
  FileExcelOutlined,
  FileTextOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { ExportableTable } from '../../../../shared/Exporter';
import { CSVLink } from 'react-csv';
import ExportToFormat from '../ExportToFormat';
import './style.less';
import { Tools } from '../../../../shared';
import { ContextApp } from '../../../../contexts/ContextApp';

// TODO: Agregar documentación
export interface ExporterProps<T> {
  className?: string;
  exportable: ExportableTable<T>;
  disabled?: boolean;
}

const ExporterDropdown: React.FC<ExporterProps<any>> = (props) => {
  const { t } = useContext(ContextApp);
  const { exportable, className } = props;
  const [dataExport, setDataExport] = useState<any[]>([]);
  const [headersExport, setHeadersExport] = useState<any[]>([]);

  const loadDataExport = (): any => {
    const dataExportLink: any[] = [];
    exportable.items.forEach((item) => {
      const it: any = {};
      exportable.columns.forEach((col: any) => {
        if (col.export && col.dataIndex) {
          it[col.dataIndex.toString()] = col.renderDataExport
            ? col.renderDataExport(item)
            : item[col.dataIndex.toString()];
        }
      });
      dataExportLink.push(it);
    });
    setDataExport(dataExportLink);
  };

  const loadHeadersExport = (): any => {
    const headers: any[] = [];
    exportable.columns.forEach((col) => {
      if (col.export) {
        headers.push({
          label: col.title,
          key: col.dataIndex,
        });
      }
    });
    setHeadersExport(headers);
  };

  useEffect(() => {
    if (!props.disabled) {
      loadDataExport();
      loadHeadersExport();
    }
  }, [props.disabled, exportable]);

  const exportMenu = (
    <div className="MenuDropdown ant-dropdown-menu">
      <div key="1">
        <CSVLink
          filename={`${exportable.filename}.csv`}
          data={dataExport}
          headers={headersExport}
        >
          <Button type="text" icon={<FileExcelOutlined />}>
            CSV
          </Button>
        </CSVLink>
      </div>
      <div key="2">
        <ExportToFormat
          filename={`${exportable.filename}`}
          data={dataExport}
          headers={headersExport}
          format="xlsx"
        >
          <Button type="text" icon={<FileExcelOutlined />}>
            XLSX
          </Button>
        </ExportToFormat>
      </div>
      <div key="3">
        <ExportToFormat
          filename={`${exportable.filename}`}
          data={dataExport}
          headers={headersExport}
          format="txt"
        >
          <Button type="text" icon={<FileTextOutlined />}>
            TXT
          </Button>
        </ExportToFormat>
      </div>
    </div>
  );

  return (
    <>
      <Dropdown
        className={className}
        dropdownRender={() => exportMenu}
        disabled={!!props.disabled || false}
      >
        <Button>
          {Tools.capitalize(t('action.export'))} <DownOutlined />
        </Button>
      </Dropdown>
    </>
  );
};

export default ExporterDropdown;
