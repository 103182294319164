export const OPERATIONS = `query operations(
  $tenant_id: Int!
  $filter:  FilterOperationInput,
  $orderBy:  OperationOrderInput,
  $searchText : String,
  $skip: Int,
  $take: Int,
) {
  operations(
    tenant_id: $tenant_id
    filter: $filter
    orderBy: $orderBy
    searchText : $searchText,
    skip: $skip
    take: $take
  ) {
    __typename
    ... on Operation{
      isOptimistic @client
      version
      id
      name
      start_date
      end_date
      tags
      important
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const EXPORT_OPERATIONS = `
query exportOperations(
  $tenant_id: Int!
  $searchText: String
  $filter: FilterOperationInput
  $orderBy: OperationOrderInput
  $format: ExportFormat!
) {
  exportOperations(
    tenant_id: $tenant_id
    searchText: $searchText
    filter: $filter
    orderBy: $orderBy
    format: $format
  ) {
    __typename
    ... on ExportResult {
      file
      mimetype
      encoding
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
