import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { EnumsValues } from './enums/EnumsValues';
import { useCustomLazyQuery } from './hooks/apollo/ApolloCustomHooks';
import { IAppSetting } from './interfaces';
import { Query } from './services/graphql/query';

const SendActivity = () => {
  const location = useLocation();
  const [fetchGAClientID] = useCustomLazyQuery<{
    getAppSettingByKey: IAppSetting;
  }>(Query.getAppSettingByKey, {
    variables: {
      input: { key: EnumsValues.SettingNames.GAClientID },
    },
  });

  const sendActivity = async () => {
    //Consultar ID-Flujo-GA para estadísticas
    try {
      const { data, error } = await fetchGAClientID();

      if (error) {
        throw error;
      }

      if (data?.getAppSettingByKey) {
        let src =
          'https://www.googletagmanager.com/gtag/js?id=' +
          data?.getAppSettingByKey.setting_value;

        let ga_script = document.createElement('script');
        ga_script.setAttribute('src', src);
        document.head.appendChild(ga_script);

        let scriptBody: string =
          'window.dataLayer = window.dataLayer || [];' +
          ' function gtag(){dataLayer.push(arguments);}' +
          " gtag('js', new Date()); gtag('config', '" +
          data?.getAppSettingByKey.setting_value +
          "');";

        let ga_data = document.createElement('script');
        ga_data.text = scriptBody;
        document.head.appendChild(ga_data);
      }
    } catch (error: any) {
      console.error(error.message);
      throw error;
    }
  };

  useEffect(() => {
    sendActivity();
  }, [location]);
};

export default SendActivity;
