export const CREATE_OPERATION = `mutation createOperation(
  $tenant_id: Int!,
  $input: CreateOperationInput!,
){
  createOperation(
    tenant_id: $tenant_id,
    input: $input
  ){
    __typename
    ... on Operation{
      isOptimistic @client
      version
      id
      name
      start_date
      end_date
      important
      tags
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}`;

export const UPDATE_OPERATION = `
mutation updateOperation(
  $tenant_id: Int!,
	$input: UpdateOperationInput!,
) {
  updateOperation(
    tenant_id: $tenant_id,
    input: $input,
  ) {
    __typename
    ... on Operation {
      isOptimistic @client
      version

      id
      name
      start_date
      end_date
      important
      tags
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const DELETE_OPERATION = `mutation deleteOperation(
  $tenant_id: Int!,
  $id: Int!
  $version: Int!
){
  deleteOperation(
    tenant_id: $tenant_id,
    id: $id
    version: $version
  ){
    __typename
    ... on Operation{
      isOptimistic @client
      version

      id
      name
      start_date
      end_date
      important
      tags
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}`;

export const UPDATE_IMPORTANT_OPERATION = `
mutation UpdateImportantOperation($tenant_id: Int!,$id: Int!, $important: Boolean!) {
  updateImportantOperation(tenant_id:$tenant_id, id: $id, important: $important) {
    ... on Operation {
      isOptimistic @client
      version
      id
      name
      start_date
      end_date
      important
      tags
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
