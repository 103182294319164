import { USER_PLAN_UPDATED } from './UserPlan';
import { OPERATION_CREATED, OPERATION_UPDATED } from './operation';

const Subscription = {
  operationCreated: OPERATION_CREATED,
  operationUpdated: OPERATION_UPDATED,
  userPlanUpdated: USER_PLAN_UPDATED,
};

export { Subscription };
