import { FetchResult } from '@apollo/client';
import { ICompany } from '../../../interfaces/Company';
import {
  CacheMutationsRecord,
  CacheRollbackMutationsRecord,
  MutationHandler,
  MutationRollback,
} from './types';
import { parse, stringify } from 'flatted';

type Mutations = 'createCompany' | 'deleteCompany' | 'updateCompany';

// En este archivo se determina como se modifica la cache ante las diferentes mutaciones, y sus respectivos rollback en caso de error
// Por cada mutación podemos contar con múltiples campos de la cache que deben ser modificados

const handleCreateCompany: MutationHandler<{
  createCompany: ICompany;
}> = {
  companies: (previousQueryResult, { mutationResult }) => {
    const _companies: ICompany[] = previousQueryResult?.companies || [];
    const companies = [..._companies];
    const newCompany = mutationResult.data?.createCompany!;

    const index = companies.findIndex(
      (company) => company.id === newCompany.id,
    );

    // Replace at existing index or push to the end
    index === -1
      ? companies.push(newCompany)
      : companies.splice(index, 1, newCompany);

    return { companies };
  },
};

const handleRollbackCreateCompany: MutationRollback<ICompany> = {
  companies: (
    queryCurrentValue,
    mutationCurrentValue,
    _mutationPreviousValue,
  ) => {
    const companies: ICompany[] = queryCurrentValue?.companies || [];
    const id = mutationCurrentValue?.id;
    return { companies: companies.filter((company) => company.id !== id) };
  },
};

const handleUpdateCompany: MutationHandler<{
  updateCompany: ICompany;
}> = {
  companies: (previousQueryResult, { mutationResult }) => {
    const _companies: ICompany[] = previousQueryResult?.companies || [];
    const companies: typeof _companies = parse(stringify(_companies));
    const updatedCompany = mutationResult.data?.updateCompany!;
    const index = companies.findIndex(
      (company) => company.id === updatedCompany.id,
    );

    // Replace at existing index or push to the end
    index === -1
      ? companies.push(updatedCompany)
      : companies.splice(index, 1, updatedCompany);

    return { companies };
  },
};

const handleRollbackUpdateCompany: MutationRollback<ICompany> = {
  companies: (
    queryCurrentValue,
    mutationCurrentValue,
    mutationPreviousValue,
  ) => {
    const _companies: ICompany[] = queryCurrentValue?.companies || [];
    const companies = [..._companies];
    const index = companies.findIndex(
      (company) => company.id === mutationCurrentValue?.id,
    );

    if (mutationPreviousValue) {
      // Replace at existing index or push to the end
      index === -1
        ? companies.push(mutationPreviousValue)
        : companies.splice(index, 1, mutationPreviousValue);
    }

    return { companies };
  },
};

const handleDeleteCompany: MutationHandler<{
  deleteCompany: ICompany;
}> = {
  companies: (previousQueryResult, { mutationResult }) => {
    const companies: ICompany[] = previousQueryResult?.companies || [];
    const id = mutationResult.data?.deleteCompany?.id;
    return { companies: companies.filter((company) => company.id !== id) };
  },
};

const handleRollbackDeleteCompany: MutationRollback<ICompany> = {
  companies: (
    queryCurrentValue,
    _mutationCurrentValue,
    mutationPreviousValue,
  ) => {
    const companies: ICompany[] = queryCurrentValue?.companies || [];
    const newCompany = mutationPreviousValue;
    return { companies: [...companies, newCompany] };
  },
};

const optimisticValuesCreateCompany = (
  optimisticData: { createCompany: ICompany },
  data: FetchResult<{ createCompany: ICompany }>,
) => {
  if (!optimisticData || !data) return [];

  const optimisticId = optimisticData.createCompany.id;

  const realId = data.data?.createCompany.id;

  if (!optimisticId || !realId) return [];

  return [[optimisticId, realId]] as ([string, string] | [number, number])[];
};

export const companyCacheHandlers: CacheMutationsRecord<Mutations, ICompany> = {
  createCompany: handleCreateCompany,
  updateCompany: handleUpdateCompany,
  deleteCompany: handleDeleteCompany,
};

export const companyCacheRollbackHandlers: CacheRollbackMutationsRecord<
  Mutations,
  ICompany
> = {
  createCompany: handleRollbackCreateCompany,
  updateCompany: handleRollbackUpdateCompany,
  deleteCompany: handleRollbackDeleteCompany,
};

export const optimisticValuesToReplaceCompany: {
  [Mutation in Mutations]: (
    optimisticData: { [key in Mutation]: ICompany },
    data: FetchResult<{ [key in Mutation]: ICompany }>,
  ) => ([string, string] | [number, number])[];
} = {
  createCompany: optimisticValuesCreateCompany,
  deleteCompany: () => [],
  updateCompany: () => [],
};
