export const CREATE_COMPANY = `
mutation createCompany(
  $tenant_id: Int!,
  $input: CreateCompanyInput!,
){
  createCompany(
    tenant_id: $tenant_id
    input: $input
  ){
    __typename
    ... on Company{
      isOptimistic @client
      version
      id
      name
      description
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const UPDATE_COMPANY = `
mutation updateCompany(
  $tenant_id: Int!,
  $input:  UpdateCompanyInput!,
){
  updateCompany(
    tenant_id: $tenant_id
    input: $input
  ){
    __typename
    ... on Company{
      isOptimistic @client
      version
      id
      name
      description
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const DELETE_COMPANY = `
mutation deleteCompany(
  $tenant_id: Int!
  $id: Int!
  $version: Int!
){
  deleteCompany(
    tenant_id: $tenant_id
    id: $id
    version: $version
  ){
    __typename
    ... on Company{
      isOptimistic @client
      version
      id
      name
      description
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
