export const CREATE_TENANT = `
mutation createTenant($tenant_id: Int!, $input: CreateTenantInput!, $file: Upload) {
  createTenant(tenant_id: $tenant_id, input: $input, file: $file) {
    __typename
    ... on Tenant {
      id
      name
      slug
      active
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const DELETE_TENANT = `
mutation deleteTenants($tenant_id: Int!, $ids: [Int!]!) {
  deleteTenants(tenant_id: $tenant_id, ids: $ids) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const UPDATE_TENANT = `
mutation updateTenant (
  $tenant_id: Int!, 
  $id: Int!, 
  $input: UpdateTenantInput!, 
  $file: Upload) {
  updateTenant(
    tenant_id: $tenant_id, 
    id: $id, 
    input: $input, 
    file: $file) {
    __typename
    ... on Tenant {
      id
      name
      slug
      active
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
