import gql from 'graphql-tag';

export const OPERATION_CREATED = gql`
  subscription operationCreated {
    operationCreated {
      name
      start_date
      end_date
    }
  }
`;

export const OPERATION_UPDATED = gql`
  subscription ($operationId: Int!) {
    operationUpdated(operationId: $operationId) {
      id
      name
      start_date
      end_date
      dts
    }
  }
`;
