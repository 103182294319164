export const NOTES = `query notes(
  $tenant_id: Int!,
  $filter:  FilterNoteInput,
  $orderBy:  NoteOrderInput,
  $searchText : String,
  $skip: Int,
  $take: Int,
){
  notes(
  tenant_id: $tenant_id
  filter: $filter
  orderBy: $orderBy
  searchText : $searchText,
  skip: $skip
  take: $take
)
{
  __typename
  ... on Note{
    id
    title
    body
    its
    uts
  }
  ... on ResultError{
      status_code
      message
      message_translation_key
    }
}
}`;
