import { print } from 'graphql';
import {
  USERS,
  AUTH,
  LOGOUT,
  RESET_PASSWORD,
  VERIFY_RECOVERY_PASSWORD_TOKEN,
  USERS_COUNT_BY_ROLE,
  USERS_COUNT,
  USER_HAS_PASSWORD,
  TOKEN_USER_HAS_PASSWORD,
  GET_PROFILE_PICTURE,
  GET_PROFILE_FILE,
  USER_IS_VALIDATED,
} from './User';
import { COMPANY, COMPANIES } from './Company';
import { SETTINGS, SETTING_KEY, SETTING_KEY_FOR_ADMIN } from './AppSetting';
import { LANGUAGES } from './Language';
import { ROLE, ROLES } from './Role';
import { PERMISSIONS } from './Permission';
import { ROLE_PERMISSION, ROLE_PERMISSIONS } from './RolePermission';
import { EXPORT_OPERATIONS, OPERATIONS } from './Operation';
import { TAGS } from './Tag';
import { GET_SIGNATURE } from './Signature';
import { IQuery } from '../GraphqlService';
import { ObjectStore } from '../../../enums/EnumsValues';
import {
  GET_TENANT_PICTURE,
  TENANT,
  TENANTS,
  TENANTS_ASSOCIATED_WITH_USER,
  TENANT_COUNT,
  TENANT_FOR_ACCOUNT_BANK_INFORMATION,
  TENANT_HAS_BANK_INFORMATION,
} from './Tenant';
import { VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN } from './VeryTokens';
import { LOGIN_PROVIDERS } from './LoginProvider';
import { LOGIN_METHODS } from './LoginMethod';
import { SEND_EMAIL, SEND_CONTACT_EMAIL } from './Email';
import { CHECK_INVITATION, INVITATIONS } from './Invitation';
import {
  TERMS_AND_CONDITIONS,
  TERMS_AND_CONDITIONS_LIST,
  USER_HAS_ACCEPTED_TERMS_AND_CONDITIONS,
} from './TermsAndConditions';
import {
  TENANT_SETTING,
  TENANT_SETTINGS,
  TENANT_SETTINGS_COUNT,
} from './TenantSetting';
import { NOTES } from './Note';
import { PLANS } from './Plan';
import { USER_PLANS } from './UserPlan';
import { CATEGORIES } from './Category';
import { PRODUCT, PRODUCTS, PRODUCT_COUNT } from './Product';
import { SUPPLIERS } from './Supplier';
import { PRODUCT_LOGS, PRODUCT_LOGS_WITH_STOCK } from './ProductLog';
import { STOCK_REASONS } from './StockReason';
import { SHOP, SHOPS, SHOP_COUNT } from './Shop';
import { AVAILABLE_ORDER_ACTIONS, ORDER, ORDERS, ORDER_COUNT } from './Order';
import { ORDER_STATUSES } from './OrderStatus';
import { ORDER_LOGS } from './OrderLog';
import { WALLET_MOVEMENT_COUNT, WALLET_MOVEMENTS } from './WalletMovement';
import { WALLET_REASONS } from './WalletReason';
import { STATS } from './Stats';
import { WALLET_STATS } from './WalletStats';

let Query = {
  authenticate: { name: 'authenticate', gql: AUTH },
  companies: {
    name: 'companies',
    gql: COMPANIES,
    objectStore: ObjectStore.Company,
  },
  company: { name: 'company', gql: COMPANY },
  getAppSettingByKey: { name: 'getAppSettingByKey', gql: SETTING_KEY },
  getAppSettings: { name: 'getAppSettings', gql: SETTINGS },
  languages: { name: 'languages', gql: print(LANGUAGES) },
  logout: { name: 'logout', gql: LOGOUT },
  operations: {
    name: 'operations',
    gql: OPERATIONS,
    objectStore: ObjectStore.Operation,
  },
  notes: { name: 'notes', gql: NOTES },
  plans: { name: 'plans', gql: print(PLANS) },
  userPlans: { name: 'userPlans', gql: USER_PLANS },
  permissions: { name: 'permissions', gql: PERMISSIONS },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  role: { name: 'role', gql: ROLE },
  roles: { name: 'roles', gql: ROLES },
  rolePermission: { name: 'rolePermission', gql: ROLE_PERMISSION },
  rolePermissions: { name: 'rolePermissions', gql: ROLE_PERMISSIONS },
  users: { name: 'users', gql: USERS },
  userCount: { name: 'userCount', gql: USERS_COUNT },
  userCountByRole: { name: 'userCountByRole', gql: USERS_COUNT_BY_ROLE },
  userHasPassword: { name: 'userHasPassword', gql: USER_HAS_PASSWORD },
  tokenUserHasPassword: {
    name: 'tokenUserHasPassword',
    gql: TOKEN_USER_HAS_PASSWORD,
  },
  verifyRecoveryPasswordToken: {
    name: 'verifyRecoveryPasswordToken',
    gql: VERIFY_RECOVERY_PASSWORD_TOKEN,
  },
  getProfilePicture: { name: 'getProfilePicture', gql: GET_PROFILE_PICTURE },
  getProfileFile: { name: 'getProfileFile', gql: GET_PROFILE_FILE },
  getSignature: { name: 'getSignature', gql: GET_SIGNATURE },
  tenants: { name: 'tenants', gql: TENANTS },
  tenant: { name: 'tenant', gql: TENANT },
  getTenantPicture: {
    name: 'getTenantPicture',
    gql: GET_TENANT_PICTURE,
  },
  tenantCount: { name: 'tenantCount', gql: TENANT_COUNT },
  exportOperations: { name: 'exportOperations', gql: EXPORT_OPERATIONS },
  verifyCompleteRegisterByEmailToken: {
    name: 'verifyCompleteRegisterByEmailToken',
    gql: VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN,
  },
  tags: { name: 'tags', gql: TAGS, objectStore: ObjectStore.Tag },
  loginProviders: { name: 'loginProviders', gql: LOGIN_PROVIDERS },
  loginMethods: { name: 'loginMethods', gql: LOGIN_METHODS },
  sendEmail: { name: 'sendEmail', gql: SEND_EMAIL },
  sendContactEmail: { name: 'sendContactEmail', gql: SEND_CONTACT_EMAIL },
  termsAndConditions: { name: 'termsAndConditions', gql: TERMS_AND_CONDITIONS },
  userHasAcceptedTermsAndConditions: {
    name: 'userHasAcceptedTermsAndConditions',
    gql: USER_HAS_ACCEPTED_TERMS_AND_CONDITIONS,
  },
  termsAndConditionsList: {
    name: 'termsAndConditionsList',
    gql: TERMS_AND_CONDITIONS_LIST,
  },
  checkInvitation: {
    name: 'checkInvitation',
    gql: CHECK_INVITATION,
  },
  invitations: {
    name: 'invitations',
    gql: INVITATIONS,
  },
  tenantsAssociatedWithUser: {
    name: 'tenantsAssociatedWithUser',
    gql: TENANTS_ASSOCIATED_WITH_USER,
  },
  tenantSettings: {
    name: 'tenantSettings',
    gql: TENANT_SETTINGS,
  },
  tenantSettingsCount: {
    name: 'tenantSettingsCount',
    gql: TENANT_SETTINGS_COUNT,
  },
  tenantSetting: {
    name: 'tenantSetting',
    gql: TENANT_SETTING,
  },
  getAppSettingByKeyForAdmin: {
    name: 'getAppSettingByKeyForAdmin',
    gql: SETTING_KEY_FOR_ADMIN,
  },
  categories: {
    name: 'categories',
    gql: CATEGORIES,
  },
  products: {
    name: 'products',
    gql: PRODUCTS,
  },
  suppliers: {
    name: 'suppliers',
    gql: SUPPLIERS,
  },
  productCount: {
    name: 'productCount',
    gql: PRODUCT_COUNT,
  },
  productLogs: {
    name: 'productLogs',
    gql: PRODUCT_LOGS,
  },
  product: {
    name: 'product',
    gql: PRODUCT,
  },
  productLogsWithStock: {
    name: 'productLogsWithStock',
    gql: PRODUCT_LOGS_WITH_STOCK,
  },
  stockReasons: {
    name: 'stockReasons',
    gql: STOCK_REASONS,
  },
  shops: {
    name: 'shops',
    gql: SHOPS,
  },
  shop: {
    name: 'shop',
    gql: SHOP,
  },
  shopCount: {
    name: 'shopCount',
    gql: SHOP_COUNT,
  },
  userIsValidated: {
    name: 'userIsValidated',
    gql: USER_IS_VALIDATED,
  },
  orderCount: {
    name: 'orderCount',
    gql: ORDER_COUNT,
  },
  orders: {
    name: 'orders',
    gql: ORDERS,
  },
  order: {
    name: 'order',
    gql: ORDER,
  },
  orderStatuses: {
    name: 'orderStatuses',
    gql: ORDER_STATUSES,
  },
  availableOrderActions: {
    name: 'availableOrderActions',
    gql: AVAILABLE_ORDER_ACTIONS,
  },
  orderLogs: {
    name: 'orderLogs',
    gql: ORDER_LOGS,
  },
  tenantForAccountBankInformation: {
    name: 'tenantForAccountBankInformation',
    gql: TENANT_FOR_ACCOUNT_BANK_INFORMATION,
  },
  walletMovements: {
    name: 'walletMovements',
    gql: WALLET_MOVEMENTS,
  },
  walletReasons: {
    name: ' walletReasons',
    gql: WALLET_REASONS,
  },
  tenantHasBankInformation: {
    name: ' tenantHasBankInformation',
    gql: TENANT_HAS_BANK_INFORMATION,
  },
  stats: {
    name: 'stats',
    gql: STATS,
  },
  walletStats: {
    name: 'walletStats',
    gql: WALLET_STATS,
  },
  walletMovementCount: {
    name: 'walletMovementCount',
    gql: WALLET_MOVEMENT_COUNT,
  },
};

// Check type maintaining inferred type
const checkTypeQuery = <Obj extends Record<string, IQuery>>(obj: Obj) => obj;
Query = checkTypeQuery(Query);

export { Query };
