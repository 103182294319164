import gql from 'graphql-tag';

export const PLANS = gql`
  query plans($tenant_id: Int!) {
    plans(tenant_id: $tenant_id) {
      __typename
      ... on Plan {
        id
        name
        description
        price
        active
        plan_feature {
          id
          plan_id
          feature_id
          feature {
            id
            name
            code
          }
        }
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;
