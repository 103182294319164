import { Query } from '../query';
import {
  companyCacheHandlers,
  companyCacheRollbackHandlers,
  optimisticValuesToReplaceCompany,
} from './Company';
import {
  operationCacheHandlers,
  operationCacheRollbackHandlers,
  optimisticValuesToReplaceOperation,
} from './Operation';

export const updateCacheHandlerByName = {
  ...companyCacheHandlers,
  ...operationCacheHandlers,
};

export const rollbackCacheHandlerByName = {
  ...companyCacheRollbackHandlers,
  ...operationCacheRollbackHandlers,
};

export const optimisticValuesToReplaceByOperation = {
  ...optimisticValuesToReplaceCompany,
  ...optimisticValuesToReplaceOperation,
};

export const queryRequireTenantId = [
  Query.companies.name,
  Query.operations.name,
];
