export const CHECK_INVITATION = `
query checkInvitation ($invitation_uid: String!) {
    checkInvitation (
        invitation_uid: $invitation_uid
    ) {
        __typename
        ... on Invitation {
            id
            uid
          	email
            tenant { 
                id
                name
                is_system_tenant
            }
            role { 
                id
                name
            }
            role_id
            due_date
            accepted
            is_existing_user
        }
        ... on ResultError {
            status_code
            message
            message_translation_key
        }
    }
}
`;

export const INVITATIONS = `
query invitations ($filter: FilterInvitationInput, $orderBy: InvitationOrderInput, $skip: Int, $take: Int, $tenant_id: Int!) {
    invitations (
        filter: $filter,
        orderBy: $orderBy,
        skip: $skip,
        take: $take,
        tenant_id: $tenant_id
    ) {
        __typename
        ... on Invitation {
            id
            its
            tenant_id
            role_id
            email
            role { 
                name
            }
            due_date
            accepted
        }
        ... on ResultError {
            status_code
            message
            message_translation_key
        }
    }
}
`;
