export class BackendError extends Error {
  status_code: string | number | null;
  message: string;
  message_translation_key?: string;
  constructor(
    _status_code: string | number | null,
    _message: string,
    _message_translation_key?: string,
  ) {
    super();
    this.status_code = _status_code;
    this.message = _message;
    this.message_translation_key = _message_translation_key;
  }
}

export const isBackendError = (error: any): error is BackendError => {
  return error.status_code && !Number.isNaN(error.status_code) && error.message;
};
