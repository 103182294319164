export const CREATE_NOTE = `mutation createNote(
  $input: CreateNoteInput!,
  $tenant_id: Int!,
){
  createNote(
    input: $input
    tenant_id: $tenant_id
  ){
    __typename
    ... on Note{
      id
      title
      body
      its
      uts
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}`;

export const UPDATE_NOTE = `mutation updateNote(
  $input: UpdateNoteInput!,
  $tenant_id: Int!,
) {
  updateNote(
    input: $input,
    tenant_id: $tenant_id
  ) {
    __typename
    ... on Note {
      id
      title
      body
      its
      uts
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}`;

export const DELETE_NOTE = `mutation deleteNote(
  $id: Int!
  $tenant_id: Int!,
){
  deleteNote(
    id: $id
    tenant_id: $tenant_id
  ){
        __typename
    ... on Note{
      id
      title
      body
      its
      uts
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}`;
