import gql from 'graphql-tag';

export const USER_PLAN_UPDATED = gql`
  subscription userPlanUpdated($tenant_id: Int!) {
    userPlanUpdated(tenant_id: $tenant_id) {
      id
      plan_id
      user_id
      status {
        id
        name
      }
      plan {
        id
        active
      }
    }
  }
`;
