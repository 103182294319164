export const LOGIN_METHODS = `query loginMethods(
  $tenant_id: Int!,
  $userId:  Int!,
  $orderBy:  LoginMethodOrderInput,
  $skip: Int,
  $take: Int,
){
  loginMethods(
tenant_id: $tenant_id
userId: $userId
orderBy: $orderBy
skip: $skip
take: $take
)
{
  __typename
  ... on LoginMethod{
    id
    user_id
    login_provider_id
    uid
  }
  ... on ResultError{
      status_code
      message
  }
}
}`;
