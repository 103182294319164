export const GET_SIGNATURE = `
query getSignature($id: Int!) {
  getSignature(id: $id) {
    __typename
    ... on Signature {
      id
      filename
      encoding
      mimetype
      file
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
