export const COMPANY = `
query company(
  $tenant_id: Int!,
  $id: Int! 
){
  company(
    tenant_id: $tenant_id
    id: $id
  ){
    __typename
    ... on Company{
      isOptimistic @client
      version
      id
      name
      description
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const COMPANIES = `
query companies(
  $tenant_id: Int!,
  $searchText: String,
  $filter:  FilterCompanyInput,
  $orderBy:  CompanyOrderInput,
  $skip: Int,
  $take: Int
){
  companies(
    tenant_id: $tenant_id
    searchText: $searchText
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
  ){
    __typename
    ... on Company{
      isOptimistic @client
      version
      id
      name
      description
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
