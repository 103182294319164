import { useState, useEffect, useCallback } from 'react';

export interface useServiceWorkerProps {
  currentRoute?: string;
  disableNotification?: boolean;
  disableAutoUpdate?: boolean;
}

export const useServiceWorker = ({
  currentRoute,
  disableNotification,
  disableAutoUpdate,
}: useServiceWorkerProps) => {
  const [workerFinished, setWorkerFinished] = useState<boolean>(false);
  const [showWorkerNotification, setShowWorkerNotification] =
    useState<boolean>(false);

  useEffect(() => {
    const installedEventHandler = () => {
      setWorkerFinished(true);
      if (disableNotification) {
        reloadPage();
        return;
      } else {
        setShowWorkerNotification(true);
      }
    };

    if (!disableAutoUpdate) {
      window.addEventListener('serviceworkerinstalled', installedEventHandler);
    }
    return () => {
      window.removeEventListener(
        'serviceworkerinstalled',
        installedEventHandler,
      );
    };
  }, []);

  useEffect(() => {
    if (workerFinished && currentRoute) {
      reloadPage();
    }
  }, [currentRoute]);

  const postponeFinishedNotification = useCallback(() => {
    setShowWorkerNotification(false);
    //Si se rechaza la notificación, se vuelve a mostrar a los 10 minutos
    setTimeout(() => {
      setShowWorkerNotification(true);
    }, 1000 * 60 * 10);
  }, []);

  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  return {
    workerFinished,
    reloadPage,
    postponeFinishedNotification,
    showWorkerNotification,
  };
};
