export const CREATE_SIGNATURE = `
mutation createSignature(
  $tenant_id: Int!,
  $input: SignatureInput!
  ) {
  createSignature(
    input: $input,
    tenant_id: $tenant_id
    ) {
    __typename
    ... on Signature {
      encoding
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const DELETE_SIGNATURE = `
mutation deleteSignature($id: Int!) {
  deleteSignature(id: $id) {
    __typename
    ... on Signature {
      id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
