export const UPSERT_USER_PLAN = `
mutation upsertUserPlan(
  $input: UpsertUserPlanInput!,
  $tenant_id: Int!,
){
  upsertUserPlan(
    input: $input,
    tenant_id: $tenant_id
  ){
    __typename
    ... on UserPlan{
      id
      plan_id
      user_id
      init_point
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const UPSERT_AUTHORIZED_USER_PLAN = `
mutation upsertAuthorizedUserPlan(
    $input: UpsertAuthorizedUserPlanInput!,
    $tenant_id: Int!,
  ){
    upsertAuthorizedUserPlan(
      input: $input,
      tenant_id: $tenant_id
    ){
      __typename
      ... on UserPlan{
        id
        plan_id
        user_id
        init_point
      }
      ... on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const CANCEL_USER_PLAN = `
mutation cancelUserPlan(
  $tenant_id: Int!,
  $plan_id: Int!
){
  cancelUserPlan(
    tenant_id: $tenant_id,
    plan_id: $plan_id
  ){
    __typename
    ... on UserPlan{
      id
      plan_id
      user_id
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
