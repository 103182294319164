export const SEND_EMAIL = `
query sendEmail(
  $input: SendEmailInput! 
){
  sendEmail(
      input: $input
  ){
    __typename
    ... on BooleanResult{
      result
    }
    ... on ResultError{
        status_code
        message
        message_translation_key
    }
  }
}
`;

export const SEND_CONTACT_EMAIL = `
query sendContactEmail(
  $input: SendContactEmailInput! 
){
  sendContactEmail(
      input: $input
  ){
    __typename
    ... on BooleanResult{
      result
    }
    ... on ResultError{
        status_code
        message
        message_translation_key
    }
  }
}
`;
