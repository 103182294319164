export const TENANT_SETTING = `
query TenantSetting($name: String!, $tenant_id: Int!) {
  tenantSetting(name: $name, tenant_id: $tenant_id) {
    ... on TenantSettingValue {
      value
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}

`;

export const TENANT_SETTINGS = `
query tenantSettings(
  $tenant_id: Int!
  $filter: FilterTenantSettingInput
  $orderBy: TenantSettingOrderInput
  $skip: Int
  $take: Int
  $searchText: String
) {
  tenantSettings(
    tenant_id: $tenant_id
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
    searchText: $searchText
  ) {
    __typename
    ... on TenantSetting {
      id
      its
      uts
      dts
      name
      description
      default_value
      tenant_settings_current_value {
        value
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const TENANT_SETTINGS_COUNT = `
query tenantSettingsCount(
  $tenant_id: Int!
  $filter: FilterTenantSettingInput
  $orderBy: TenantSettingOrderInput
  $searchText: String
) {
  tenantSettingsCount(
    tenant_id: $tenant_id
    filter: $filter
    orderBy: $orderBy
    searchText: $searchText
  ) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;
