export const LOGIN_PROVIDERS = `query loginProviders(
  $tenant_id: Int!,
  $orderBy:  LoginProviderOrderInput,
  $skip: Int,
  $take: Int,
){
  loginProviders(
tenant_id: $tenant_id
orderBy: $orderBy
skip: $skip
take: $take
)
{
  __typename
  ... on LoginProvider{
    id
    name
    code
  }
  ... on ResultError{
      status_code
      message
  }
}
}`;
