export const WALLET_STATS = `
query walletStats(
  $tenant_id: Int!,
  $filter: FilterWalletMovementInput,
){
  walletStats(
    tenant_id:$tenant_id,
    filter:$filter,
  ){
    __typename
    ...on WalletStats{
      income
      outflow
      projected_income
      wallet_reason_stats{
        wallet_reason{
          name
          translation_key
          type
        }
        amount
      }
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
