import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { ContextApp } from '../../contexts/ContextApp';
import { Authorization } from '../../shared';
import { EnumsValues } from '../../enums/EnumsValues';
const DefaultRedirectPage = () => {
  const {
    user,
    functions,
    isRequiredAcceptTemrsAndConditions,
    userHasAcceptedTermsAndConditions,
  } = useContext(ContextApp);
  if (user) {
    if (
      !userHasAcceptedTermsAndConditions &&
      !Authorization.security(functions, EnumsValues.Functions.IsAdmin) &&
      isRequiredAcceptTemrsAndConditions
    )
      return <Redirect to="/app/termsandconditions" />;
    return <Redirect to="/app/home" />;
  } else {
    return <Redirect to="/login" />;
  }
};

export default DefaultRedirectPage;
