import {
  useQuery,
  gql,
  useMutation,
  QueryHookOptions,
  MutationHookOptions,
  useLazyQuery,
  LazyQueryHookOptions,
} from '@apollo/client';
import { IRequest } from '../../services/graphql/GraphqlService';
import { getContextForRequest } from '../../shared/ApolloHooksUtils';

const useCustomQuery = <T extends Record<string, any>>(
  query: IRequest,
  queryHookOptions: QueryHookOptions = {},
) => {
  const context = queryHookOptions.context || {};

  const queryData = useQuery<T>(gql(query.gql), {
    ...queryHookOptions,
    context: {
      ...context,
      ...getContextForRequest(query),
    },
  });

  return queryData;
};

const useCustomMutation = <T extends Record<string, any>>(
  mutation: IRequest,
  mutationHookOptions: MutationHookOptions<T> = {},
) => {
  const context = mutationHookOptions.context || {};

  const mutationData = useMutation<T>(gql(mutation.gql), {
    ...mutationHookOptions,
    context: {
      ...context,
      ...getContextForRequest(mutation),
    },
  });

  return mutationData;
};

const useCustomLazyQuery = <T extends Record<string, any>>(
  query: IRequest,
  lazyQueryHookOptions: LazyQueryHookOptions = {},
) => {
  const context = lazyQueryHookOptions.context || {};

  const lazyQueryData = useLazyQuery<T>(gql(query.gql), {
    ...lazyQueryHookOptions,
    context: {
      ...context,
      ...getContextForRequest(query),
    },
  });

  return lazyQueryData;
};

export { useCustomQuery, useCustomMutation, useCustomLazyQuery };
