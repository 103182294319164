import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
} from "@apollo/client/core";
import { Observer } from "zen-observable-ts";
import { InternalError, Tools } from "../shared/Tools";
import { ApolloContextKey } from "../enums/ApolloContextKey";
export interface OperationQueueEntry {
  operation: Operation;
  forward: NextLink;
  observer: Observer<FetchResult>;
  subscription?: { unsubscribe: () => void };
}

function extractKeys(operation: Operation): [string] {
  return operation.getContext().serializationKey;
}

/**
 * Serialize queries with the same context.serializationKey, meaning that
 * all previous queries must complete for the next query with the same
 * context.serializationKey to be started.
 *
 * Actions are typically made in this order:
 *   - Enqueue all queries in an array per key
 *   - Once returned a result, "observer.complete()" is called,
 *     and the Observable cleanup function is run, removing the query
 *     from the array, and running the first (next) query in that array
 *
 */
export default class CustomSerializingLink extends ApolloLink {
  private opQueues: { [key: string]: OperationQueueEntry[] } = {};

  public request(operation: Operation, forward: NextLink) {
    const keys = extractKeys(operation);
    if (!keys?.length) {
      return forward(operation);
    }

    return new Observable((observer: Observer<FetchResult>) => {
      const entry = { operation, forward, observer };
      this.enqueue(keys, entry);

      // Cleanup function
      return () => {
        // Necessary when error. Duplicated (unnecessary) when successful (no error).
        this.cleanUp(keys, entry);
      };
    });
  }

  /**
   * Add an operation to the end of the queue. If it is the first operation in the queue, start it.
   */
  private enqueue = (keys: [string], entry: OperationQueueEntry) => {
    for (const key of keys) {
      if (!this.opQueues[key]) {
        this.opQueues[key] = [];
      }
      this.opQueues[key].push(entry);
    }
    if (keys.every((key) => this.opQueues[key].length === 1)) {
      this.startFirstOpIfNotStarted(keys[0]);
    }
  };

  /**
   * Cancel the operation by removing it from the queue and unsubscribing if it is currently in progress.
   */
  private cleanUp = (keys: string[], entryToRemove: OperationQueueEntry) => {
    for (const key of keys) {
      if (!this.opQueues[key]) {
        /* should never happen */ continue;
      }
      const idx = this.opQueues[key].findIndex(
        (entry) => entryToRemove === entry
      );

      if (idx >= 0) {
        const entry = this.opQueues[key][idx];
        entry.subscription?.unsubscribe();
        this.opQueues[key].splice(idx, 1);
      }
    }
  };

  /**
   * From the stacks of the current mutation keys, take the next mutation and execute it if ready
   */
  private nextOp = (keys: [string]) => {
    const nextMutations = keys
      .map((key) => this.opQueues[key]?.[0]?.operation)
      .filter((v) => v);

    const nextMutationsUnique = [...new Set(nextMutations)];
    nextMutationsUnique.forEach((nextMutationUnique) => {
      if (this.isOperationReady(nextMutationUnique)) {
        const mutationKeys = extractKeys(nextMutationUnique);
        this.startFirstOpIfNotStarted(mutationKeys[0]);
      }
    });
  };

  /**
   * Start the first operation in the queue if it hasn't been started yet
   */
  private startFirstOpIfNotStarted = (key: string) => {
    // At this point, the queue always exists, but it may not have any elements
    // If it has no elements, we free up the memory it was using.
    if (this.opQueues[key].length === 0) {
      delete this.opQueues[key];
      return;
    }
    const op = this.opQueues[key][0];
    const { operation, forward, observer, subscription } = op;
    if (subscription) {
      return;
    }

    op.subscription = forward(operation).subscribe({
      next: (v: FetchResult) => {
        const keys = extractKeys(operation);
        this.cleanUp(keys, op);
        this.nextOp(keys);
        return observer.next?.(v);
      },
      error: (e: Error) => {
        this.killAll(op, e);
      },
      complete: () => {
        observer.complete?.();
      },
    });
  };

  /**
   * Cancela la mutación dada y las posteriores a esta, teniendo en cuenta el tipo de error
   */
  private killAll = (entry: OperationQueueEntry, error: Error) => {
    const keysIndex = this.getKeysIndex(entry);
    for (const key in keysIndex) {
      const mutationsWithError = (this.opQueues[key] ?? []).splice(
        keysIndex[key]
      );
      if (Tools.isGraphqlError(error)) {
        const firstMutation = mutationsWithError[0];

        const nextMutations = mutationsWithError.slice(1).reverse();
        for (const mutation of nextMutations) {
          mutation.observer.error?.(
            new InternalError(
              `Falló la consulta relacionada ${
                firstMutation.operation.getContext()[
                  ApolloContextKey.CUSTOM_OPERATION_NAME
                ]
              }`
            )
          );
        }

        firstMutation?.observer.error?.(error);
      } else {
        for (const mutation of mutationsWithError) {
          mutation.observer.error?.(error);
        }
      }
    }
  };

  /**
   * Devuelve los indices en cada key a partir de los cuales se deben cancelar las mutaciones
   */
  private getKeysIndex = (
    entry: OperationQueueEntry,
    keyIndexes: Record<string, number> = {}
  ) => {
    // Por cada key de la mutación
    const { operation } = entry;
    const keys = extractKeys(operation);
    for (const key of keys) {
      const keyMutations = this.opQueues[key] ?? [];
      const keyMutationIndex = keyMutations.indexOf(entry);

      const keyCurrentIndex = keyIndexes[key];

      if (keyCurrentIndex === undefined || keyCurrentIndex > keyMutationIndex) {
        keyIndexes[key] = keyMutationIndex;
      }

      const nextMutation: OperationQueueEntry | undefined =
        keyMutations[keyMutationIndex + 1];

      nextMutation && this.getKeysIndex(nextMutation, keyIndexes);
    }

    return keyIndexes;
  };

  private isOperationReady = (operation: Operation) => {
    const keys = extractKeys(operation);
    return keys.every(
      (key) => this.opQueues[key]?.[0]?.operation === operation
    );
  };
}
