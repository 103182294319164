export const REFRESH_TOKEN = `
  mutation refreshToken{
    refreshToken{
      __typename
      ... on RefreshTokenResult{
        accessToken
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;
