import * as FileSaver from 'file-saver';
import { PropsWithChildren } from 'react';
import * as XLSX from 'xlsx';

// TODO: Agregar documentacion
export interface ExportToFormatProps extends PropsWithChildren {
  data: any[];
  headers: { label: string; key: string }[];
  filename: string;
  format: 'xlsx' | 'csv' | 'txt';
  onLoading?: (isLoading: boolean) => {};
}

const ExportToFormat: React.FC<ExportToFormatProps> = (props) => {
  const { data, filename, children, headers, format, onLoading } = props;
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const exportTo = () => {
    if (onLoading) {
      onLoading(true);
    }
    const head: any = {};
    headers.forEach((element) => {
      head[element.key] = element.label;
    });
    const ws = XLSX.utils.json_to_sheet([head, ...data], { skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: format, type: 'array' });
    const blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blob, `${filename}.${format}`);
    if (onLoading) {
      onLoading(false);
    }
  };

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a onClick={() => exportTo()}>{children}</a>;
};

export default ExportToFormat;
