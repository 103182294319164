export const CREATE_TERMS_AND_CONDITIONS = `
mutation createTermsAndConditions($input:CreateTermsAndConditions!){
    createTermsAndConditions(input:$input){
      __typename    
      ...on ResponseMessage{
        status
        response
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const ACTIVE_TERMS_AND_CONDITIONS = `
mutation activateTermsAndConditions($id:Int!){
  activateTermsAndConditions(id:$id){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const ACCEPT_TERMS_AND_CONDITIONS = `
mutation acceptTermsAndConditions($tenant_id: Int!){
  acceptTermsAndConditions(tenant_id: $tenant_id){
    __typename
    ...on ResponseMessage{
      status
      response
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
